// -----------------------
// Buttons mixin
// -----------------------
@mixin button($class: false) {

  // --------------------------------
  // Default
  // --------------------------------
  @if $class == button {
    background-color: $primary-color;
    border-color: $primary-color;
    border-radius: 68px;
    border-width: 5px;
    border-style: solid;
    color: $white;
    display: inline-block;
    font-family: $header-font-family;
    font-size: rem_calc(18);
    font-family: 'GothamBold';
    line-height: 1;
    margin-bottom: $global-margin;
    padding: 11px 48px;
    text-align: center;
    transition: background-color .25s ease-out, color .25s ease-out;
    vertical-align: center;
    width: 100%;

    @include breakpoint(medium) {
      min-width: 174px;
      width: auto;
      font-size: rem_calc(22);
    }

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  // --------------------------------
  // Primary
  // --------------------------------
  @if $class == button--primary {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $white;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  // --------------------------------
  // Primary outline
  // --------------------------------
  @if $class == button--outline-primary {
    background-color: $white;
    border-color: $primary-color;
    color: $primary-color;

    &:hover,
    &:active,
    &:focus {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $white;
    }
  }

  // --------------------------------
  // Secondary
  // --------------------------------
  @if $class == button--secondary {
    background-color: transparent;
    border-color: $primary-color;
    color: $primary-color;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: $primary-color;
      color: $white;
    }
  }

  // --------------------------------
  // Secondary outline
  // --------------------------------
  @if $class == button--outline-secondary {
    background-color: transparent;
    border-color: $secondary-color;
    color: $secondary-color;

    &:hover,
    &:active,
    &:focus {
      background-color: $secondary-color;
      border-color: $secondary-color;
      color: $white;
    }
  }

  // --------------------------------
  // White
  // --------------------------------
  @if $class == button--white {
    background-color: $white;
    border-color: $white;
    color: $body-font-color;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      border-color: $white;
      color: $white;
    }
  }

  // --------------------------------
  // White outline
  // --------------------------------
  @if $class == button--outline-white {
    background-color: transparent;
    border-color: $white;
    color: $white;

    &:hover,
    &:active,
    &:focus {
      background-color: $white;
      border-color: $white;
      color: $body-font-color;
    }
  }

  // --------------------------------
  // Yellow
  // --------------------------------
  @if $class == button--yellow {
    background-color: $brand-yellow;
    border-color: $primary-color;
    color: $primary-color;

    &:hover,
    &:active,
    &:focus {
      background-color: $white;
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  // --------------------------------
  // Small
  // --------------------------------
  @if $class == button--small {
    padding-top: $global-padding * .85;
    padding-bottom: $global-padding * .8;
    min-height: rem_calc(40);
  }

  // --------------------------------
  // Basket
  // --------------------------------
  @if $class == button--basket {
    background-color: $primary-color;
    background-image: url('../images/icons/basket-icon.svg');
    background-position: 3.5rem center;
    background-repeat: no-repeat;
    background-size: 30px auto;
    border-color: $primary-color;
    color: $white;
    padding-right: 0;
    width: 100%;

    &:hover,
    &:active,
    &:focus {
      background-color: $white;
      background-image: url('../images/icons/basket-icon-primary.svg');
      border-color: $primary-color;
      color: $primary-color;
    }
  }

}
