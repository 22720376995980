@import "../settings";

.testimonial {

  &__background-image {
    background-image: url('../images/confetti-faint.svg');
    background-repeat: repeat;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @media screen and (prefers-reduced-motion: reduce) {
      transform: inherit !important;
    }
    @include breakpoint(small only) {
      transform: inherit !important;
    }
  }


  &__grid {
    @include mon-cols(11);

    @include breakpoint( large ) {
      padding: $global-padding * 3 0;
    }
  }

  &__card {
    text-align: center;

    blockquote {
      margin: 0;
    }
  }

  &__quote {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 0;
    padding: $global-padding * 4 0 0;
    position: relative;

    @include breakpoint( medium ) {
      padding: $global-padding  * 2 $global-padding * 5 0;
    }

    @include breakpoint( large ) {
      font-size: 68px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
    }

    &::before {
      background-image: url('../images/icons/quote-mark-left.svg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 50px;
      left: 0;
      top: - $global-margin;
      width: 75px;

      @include breakpoint( medium ) {
        height: 60px;
        left: 0;
        width: 99px;
      }

      @include breakpoint( large ) {
        height: 75px;
        left: -$global-margin * 2;
        width: 114px;
      }
    }

    &::after {
      background-image: url('../images/icons/quote-mark-right.svg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 50px;
      right: 0;
      width: 75px;

      @include breakpoint( medium ) {
        height: 60px;
        right: 0;
        width: 99px;
      }

      @include breakpoint( large ) {
        height: 75px;
        right: -$global-margin * 2;
        width: 114px;
      }
    }
  }

  &__cite {
    color: $brand-yellow;
    font-family: $header-font-family;
    font-size: 18px;
    margin-top: $global-margin * 2;

    @include breakpoint( medium ) {
      font-size: 24px;
    }
  }

  // &__icon {
  //   background-image: url(../images/icons/quote-mark.svg);
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   display: block;
  //   height: 42px;
  //   margin: 0 auto ($global-margin / 2);
  //   width: 32px;

  //   @include breakpoint(medium) {
  //     margin: 0 auto $global-margin * 1.5;
  //   }
  // }
}
