//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. Grid
//   4. Base Typography

@import 'util/util';

// 1. Global
// ---------
$global-font-size: 100%;
$global-width: rem-calc(1312); // 1280 + standard mon row padding
$global-lineheight: 1.5;
$foundation-palette: (
  primary: #4C3F8E,
  secondary: #E4303B,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);
$brand-yellow: #FECC38;
$brand-yellow-conflict: #E2B120;
$brand-orange: #EF8723;
$brand-green: #42B4A1;
$brand-blue: #23BCEC;
$brand-light-blue: #BFE6F5;
$brand-pink: #D2137E;
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$form-borders: #ADADAD;
$black: #000000;
$white: #ffffff;
$body-background: $white;
$body-font-color: #4C3F8E;
$body-font-family: "GothamBook", "Arial", "Helvetica Neue", "Droid Sans", sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-light: 300;
$global-weight-normal: normal;
$global-weight-medium: 600;
$global-weight-bold: 700;
$global-radius: 0;
$global-box-shadow: 0 0 20px 0 rgba(0,0,0,.1);

// Skew ratio used for slanted masks and shapes
$slant-skew-ratio: 9.12vw;
$slant-skew-degrees: 5.2deg;

// Adds a standard padding to the main content wrapper. May need adjusting per project but allows us to avoid additional JS and CLS by dynamically calculating through JS
$sticky-header-padding-default: 76px;
$sticky-header-padding-large: 134px;
$sticky-header-has-message-bar-default: 115px;
$sticky-header-has-message-bar-large: 171px;

$responsive-landscape-padding: 75%;
$responsive-widescreen-padding: 56.25%;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
  xxxlarge: 1920px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large xlarge xxlarge xxxlarge);

// 3. Grid. Used by mon-grid and some blocks
// --------------
$default-grid-columns: 1;
$default-grid-gap: 32px;
$default-column-count: 12;

// 4. Base Typography
// ------------------

$header-font-family: "GothamBlack", "Arial", "Helvetica Neue", "Droid Sans", sans-serif;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$subheader-font-family: "GothamBold", "Arial", "Helvetica Neue", "Droid Sans", sans-serif;

$eyebrow-font-family: "CandleLight", "Arial", "Helvetica Neue", "Droid Sans", sans-serif;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-color: $secondary-color;
$header-lineheight: 1.2;
$header-margin-bottom: 1rem;
$header-styles: (
  small: (
    'h1': ('font-size': 36, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 24),
    'h2': ('font-size': 32, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 16),
    'h3': ('font-size': 26, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 16),
    'h4': ('font-size': 20, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 14),
    'h5': ('font-size': 18, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 14),
    'h6': ('font-size': 16, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 14),
  ),
  medium: (
    'h1': ('font-size': 60, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 30),
    'h2': ('font-size': 52, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 30),
    'h3': ('font-size': 38, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 20),
    'h4': ('font-size': 28, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 16),
    'h5': ('font-size': 18, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 16),
    'h6': ('font-size': 16, 'line-height': $header-lineheight, 'margin-top': 0, 'margin-bottom': 16),
  ),
);
$header-text-rendering: optimizeLegibility;
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);
$anchor-color: $primary-color;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $body-font-color;
$blockquote-padding: 0;
$blockquote-border: 0;
$cite-font-size: rem-calc(20);
$cite-font-style: normal;
$cite-color: $body-font-color;
$cite-pseudo-content: '';
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// XX. Mixins. All mixins should be in one place, at the bottom so they can use the variables above.
// -----------
@import "mixins-layout";
@import "mixins-buttons";
@import "mixins-responsive-embed";
